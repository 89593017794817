#new-card-form {
    aspect-ratio: 2/1;
}

#error-message {
    color: red;
}

.submit-button {
    background-color: rgb(255, 255, 255);
    color: black;
    font-size: calc(10px + .5vw);
    border-radius: 10px;
}