#board {
    display: flex;
    flex-direction: column;
    align-items: center;
}

h2 {
    text-align: center;
    font-size: 2vw;
}

ul {
    margin-left: 5vw;
    margin-right: 5vw;
    display: grid;
    grid-template: 1fr / 1fr 1fr 1fr;
    column-gap: 3vw;
    row-gap: 3vw;
    width: 50vw;
}


